import {
	ListItemButton as JoyListItemButton,
	type ListItemButtonProps as JoyListItemButtonProps,
} from '@mui/joy';
import React from 'react';

import {useIsPathname} from '@/hooks';

export type AppBarListItemButtonProps = JoyListItemButtonProps & {
	readonly href?: string;
};

/**
 * Custom MUI [ListItemButton]{@link https://mui.com/joy-ui/react-list/#interactive-list-items}
 * for the App Bar
 */
export default function AppBarListItemButton({
	color,
	href,
	selected,
	sx,
	...props
}: AppBarListItemButtonProps) {
	color = color ?? 'primary';
	const isLink = props.component === 'a';
	const isRouteSelected = useIsPathname(href);

	return (
		// @ts-expect-error -- Not sure how to get types to work for this component
		<JoyListItemButton
			color={color}
			href={isLink && isRouteSelected ? undefined : href}
			selected={isLink ? isRouteSelected : selected}
			sx={[
				...(color === 'primary'
					? [
							{
								backgroundColor: 'primary.900',
								// Hover
								'&:not(.Mui-selected, [aria-selected="true"]):hover': {
									backgroundColor: 'primary.800',
								},
								// Active
								'&:not(.Mui-selected, [aria-selected="true"]):active': {
									backgroundColor: 'primary.700',
								},
							},
					  ]
					: []),
				...(Array.isArray(sx) ? sx : [sx]),
			]}
			variant="solid"
			{...props}
		/>
	);
}
