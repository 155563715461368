/**
 * @todo Replace this with the official Joy UI App Bar once it exists. See
 *       [this GitHub issue]{@link https://github.com/mui/material-ui/issues/39736}.
 */

import {
	Box,
	ColorPaletteProp,
	Container as JoyContainer,
	Dropdown,
	List,
	ListDivider,
	ListItem,
	ListItemDecorator,
	Menu,
	MenuButton,
	MenuItem,
	Tooltip,
} from '@mui/joy';
import is from '@sindresorhus/is';
import {ListIcon, LogOutIcon, MailIcon, PlusIcon, UserIcon} from 'lucide-react';
import React, {Fragment, type ReactNode} from 'react';

import {useFirebaseAuthState} from '@/hooks';

import AppBarListItemButton from './app-bar-list-item-button';

const cloudEnvironment = process.env.CLOUD_ENVIRONMENT;
let logoColor: ColorPaletteProp | undefined;

switch (cloudEnvironment) {
	case 'development': {
		logoColor = 'success';
		break;
	}

	case 'staging': {
		logoColor = 'warning';
		break;
	}

	case 'production': {
		logoColor = undefined;
		break;
	}

	default: {
		logoColor = 'danger';
	}
}

type Props = {
	readonly center?: ReactNode;
	readonly fullWidth?: boolean;
	readonly size?: 'sm' | 'md';
};

export default function AppBar({
	center,
	fullWidth = false,
	size = 'md',
}: Props) {
	const [user, isUserLoading] = useFirebaseAuthState();

	const isUserLoggedIn = !isUserLoading && !is.nullOrUndefined(user);

	const Container = fullWidth ? Fragment : JoyContainer;

	return (
		<Box sx={{backgroundColor: 'primary.900'}}>
			<Container>
				<List
					orientation="horizontal"
					size={size}
					sx={{
						padding: 0,
						...(!fullWidth && {
							// Increase width to visually align app bar content with body
							// content
							marginX: -1,
						}),
					}}
				>
					{/* Logo / Home */}
					<Tooltip
						arrow
						color={logoColor}
						title={
							cloudEnvironment === 'production'
								? undefined
								: `${cloudEnvironment} environment`
						}
					>
						<ListItem>
							<AppBarListItemButton
								color={logoColor}
								component="a"
								href="/"
								sx={{fontWeight: 'bold'}}
							>
								<ListItemDecorator>
									<Box
										component="img"
										alt="Vent Creativity logo"
										src="/logo-outlined.svg"
										sx={{
											fontSize: 'var(--Icon-fontSize, 20px)',
											height: '1em',
										}}
									/>
								</ListItemDecorator>
								Vent Creativity
							</AppBarListItemButton>
						</ListItem>
					</Tooltip>

					{isUserLoggedIn && (
						<>
							{/* Scans */}
							<ListItem>
								<AppBarListItemButton component="a" href="/scans">
									<ListItemDecorator>
										<ListIcon />
									</ListItemDecorator>
									Scans
								</AppBarListItemButton>
							</ListItem>

							{/* New Scan */}
							<ListItem>
								<AppBarListItemButton component="a" href="/scans/new">
									<ListItemDecorator>
										<PlusIcon />
									</ListItemDecorator>
									New Scan
								</AppBarListItemButton>
							</ListItem>
						</>
					)}

					{/* Center slot */}
					{center && (
						<ListItem sx={{marginInlineStart: 'auto'}}>{center}</ListItem>
					)}

					{isUserLoggedIn && (
						<>
							{/* Contact */}
							<ListItem sx={{marginInlineStart: 'auto'}}>
								<AppBarListItemButton component="a" href="/contact">
									<ListItemDecorator>
										<MailIcon />
									</ListItemDecorator>
									Contact
								</AppBarListItemButton>
							</ListItem>

							{/* User */}
							<ListItem>
								<Dropdown>
									<AppBarListItemButton component={MenuButton}>
										<UserIcon />
									</AppBarListItemButton>
									<Menu placement="bottom-end" size="sm">
										<MenuItem disabled>Logged in as {user.email}</MenuItem>

										<ListDivider />

										<MenuItem color="danger" component="a" href="/log-out">
											<LogOutIcon />
											Log out
										</MenuItem>
									</Menu>
								</Dropdown>
							</ListItem>
						</>
					)}
				</List>
			</Container>
		</Box>
	);
}
